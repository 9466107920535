import Api from "../../components/Api"

export default {
    namespaced: true, //开启命名空间
    state: {
        accessToken: '',
        supplierKey: '',
        supplierInfoData: []
    },
    mutations: {
        setAccessToken(state, token) {
            state.accessToken = token
        },
        SET_KEY(state, supplierKey) {
            state.supplierKey = supplierKey
        },
        SET_GET_INFO_DATA_MU(state, supplierInfoData) {
            state.supplierInfoData = supplierInfoData
        },
        UPDATE_GET_INFO_DATA_MU(state, index) {
            state.supplierInfoData[index].checkLabel = !state.supplierInfoData[index].checkLabel
        },
        //设置资源代理数据
        UPDATE_SET_RESOURCEAGENTS(state, prama) {
            let _data = state.supplierInfoData.filter(x => x.checkLabel).map(c => c.businessList).flat()
            prama.checkReourceList.forEach(x => {
                let find = _data[prama.index].resourceAgents.find(_x => _x.rid == x.id)
                if (!find) {
                    _data[prama.index].resourceAgents.push(
                        {
                            agentType: 0,
                            cityId: x.cityId,
                            cityNameCn: x.cityNameCn,
                            countryId: x.countryId,
                            countryNameCn: x.countryNameCn,
                            nameCn: x.nameCn,
                            nameEn: x.nameEn,
                            rid: x.id,
                            score: x.score,
                            star: x.star,
                            state: x.state
                        }
                    )
                }
            })
        },
        AGENTTYPE_SET(state, prama) {
            let _data = state.supplierInfoData.filter(x => x.checkLabel).map(c => c.businessList).flat()
            _data[prama.index].resourceAgents = prama.checkReourceList
        },
        DEL_RESOURCEAGENTS(state, prama) {
            let _data = state.supplierInfoData.filter(x => x.checkLabel).map(c => c.businessList).flat()
            _data[prama.index].resourceAgents.splice(prama.resourceIndex, 1)
        },
        //设置区域代理数据
        UPDATE_SET_SUPPLIERAREA(state, prama) {
            console.log('闯过来的数据', prama)
            let _data = state.supplierInfoData.filter(x => x.checkLabel).map(c => c.businessList).flat()
            prama.areaData.forEach(area => {
                const {pid, nameCn, nameEn, type} = area
                _data[prama.index].supplierAreas.push({
                    linkId: pid,
                    nameCn: nameCn,
                    nameEn: nameEn,
                    productState: prama.state,
                    type: type
                })
            })
        },
        DEL_SUPPLIERAREA_TYPE(state, prama) {
            let _data = state.supplierInfoData.filter(x => x.checkLabel).map(c => c.businessList).flat()
            let IndexArr = []
            _data[prama.index].supplierAreas = _data[prama.index].supplierAreas.filter(x => x.type != prama.delType)
        }
    },
    actions: {
        SET_GET_INFO_DATA(context, payload) {
            console.log('jjsjdd', context.state.supplierInfoData)
            if (context.state.supplierKey !== '' && context.state.supplierInfoData.length == 0) {
                new Api('user').getSupplierInfo({key: payload.supplierKey}).then((result) => {
                    if (result.status === 200) {
                        context.commit("SET_GET_INFO_DATA_MU", result.data.supplierType)
                        payload.success()
                    } else {
                        payload.fail()
                    }
                })
            }
        }
    },
    getters: {}
}