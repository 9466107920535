import {Service, ServiceToC} from '../Axios/Service'
import qs from "qs";
import store from '../../store';

const PROFILE = {
    user: 'VUE_APP_USER_PREFIX',
    oauth: 'VUE_APP_OAURH_PREFIX'
}

function getType(apiType) {
    return process.env[PROFILE[apiType]]
}

class Api {
    constructor(ApiType) {
        this.ApiType = ApiType;
    }
    schemeGenerate(obj) {
        let apiType = getType(this.ApiType);
        return ServiceToC({
            url: `/${apiType}/api/wechat/schemeGenerate/wintac_person`,
            method: "post",
            data: obj
        });
    }
    //公司信息查询
    selectCompanyInfo(obj) {
        let apiType = getType(this.ApiType);
        return Service({
            url: `/${apiType}/api/settle/getInfo`,
            method: "post",
            data: obj
        });
    }

    //快速获取企业信息
    tianyanQuery(keys) {
        let apiType = getType(this.ApiType);
        return Service({
            url: `/${apiType}/api/settle/tianyanQuery`,
            method: "post",
            data: qs.stringify(keys),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
    }

    tianyanQueryList(keys) {
        let apiType = getType(this.ApiType);
        return Service({
            url: `/${apiType}/api/settle/tianyanQueryList`,
            method: "post",
            data: qs.stringify(keys),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
    }

    //获取供应商注册信息
    getSupplierInfo(obj) {
        let apiType = getType(this.ApiType);
        return Service({
            url: `/${apiType}/api/settle/getInfo`,
            method: "post",
            data: obj
        });
    }

    //商户入驻新增
    settleIn(SupplierSettleRequest, key) {
        let apiType = getType(this.ApiType);
        return Service({
            url: `/${apiType}/api/settle/settleIn?key=${key}`,
            method: 'post',
            data: SupplierSettleRequest
        })
    }

    //商户入驻详情信息
    queryDetail() {
        let apiType = getType(this.ApiType);
        return Service({
            url: `/${apiType}/api/settle/queryDetail`,
            method: 'post'
        })
    }

    /**
     * 文件删除
     * @param {*} uid
     */
    delFile(uid) {
        let apiType = getType(this.ApiType);
        return Service({
            url: `/${apiType}/api/userUpload/delFile`,
            method: 'post',
            data: qs.stringify({id: uid}),
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
        })
    }

    /**
     * 多文件上传
     * files 文件对象集合
     * flodName 图片在自己系统所对应的名称
     * parentId 父级ID
     * state 父表状态
     * @param {*} uploadObj
     */
    uploadMultiple(uploadObj) {
        let apiType = getType(this.ApiType);
        var formData = new FormData();
        for (let i = 0; i < uploadObj.files.length; i++) {
            formData.append("files", uploadObj.files[i].raw);
        }

        formData.append(
            "parentId",
            new Blob([uploadObj.parentId], {type: "application/json"})
        );
        formData.append(
            "flodName",
            new Blob([uploadObj.flodName], {type: "application/json"})
        );
        formData.append(
            "state",
            new Blob([uploadObj.state], {type: "application/json"})
        );
        return Service({
            url: `/${apiType}/api/userUpload/uploadMultiple`,
            method: "post",
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }

    //发送验证码
    sendRegisterCode(phoneNumber) {
        let apiType = getType(this.ApiType);
        return Service({
            url: `/${apiType}/sms/sendRegisterCode`,
            method: "post",
            data: qs.stringify({phoneNumber: phoneNumber}),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        });
    }

    //注册供应商
    verificationCode(verificationCodeRequest) {
        let apiType = getType(this.ApiType);
        return Service({
            url: `/${apiType}/sms/verificationCode`,
            method: "post",
            data: verificationCodeRequest
        });
    }

    //获取供应商系统Token(H5)
    h5ExchangeForToken(url) {
        let apiType = getType(this.ApiType);
        return Service({
            url: `/${apiType}/user/h5ExchangeForToken`,
            method: "post",
            data: qs.stringify({redirectAddress: url}),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        });
    }

    // 获取公司信息
    getPlanCompanyInfo(obj) {
        let apiType = getType(this.ApiType);
        return ServiceToC({
            url: `/${apiType}/h5Api/user/userInfo`,
            method: "post",
            data: obj,
        });
    }

    authorizationCallbackAddressH5(id) {
        let apiType = getType(this.ApiType);
        return Service({
            url: `/${apiType}/api/wechat/authorizationCallbackAddressH5?userId=${id}`,
            method: "get",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        });
    }
    obtainSignatureData(url) {
        let apiType = getType(this.ApiType);
        return ServiceToC({
            url: `/${apiType}/api/wechat/obtainSignatureData?url=${url}`,
            method: "get",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        });
    }
    /**
     *
     * @param userId 不传userId 则不会更新数据库内openID
     * @param redirectLink
     * @returns {*}
     */
    authorizationCallbackAddressH5Transmit(userId,redirectLink) {
        let apiType = getType(this.ApiType);
        return Service({
            url: `/${apiType}/api/wechat/authorizationCallbackAddressH5Transmit?userId=${userId}&redirectLink=${redirectLink}`,
            method: "get",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        });
    }
}

export default Api;
