//Axios 拦截
import axios from 'axios'
import router from "@/router/index.js";
import store from '@/store/index'
const ConfigBaseURL = process.env.VUE_APP_SERVE //默认路径，这里也可以使用env来判断环境
let loadingInstance = null //这里是loading

export function getConfigBaseURL() {
  return ConfigBaseURL
}
//使用create方法创建axios实例
export const Service = axios.create({
  timeout: 7000, // 请求超时时间
  baseURL: ConfigBaseURL,
  method: "get",
  headers: {
    "Content-Type": "application/json;charset=UTF-8"
  },
});

export const ServiceToC = axios.create({
  timeout: 7000, // 请求超时时间
  baseURL: ConfigBaseURL,
  method: "get",
  headers: {
    "Content-Type": "application/json;charset=UTF-8"
  },
});

// 添加请求拦截器
ServiceToC.interceptors.request.use(config => {
  return config;
})
// 添加响应拦截器
ServiceToC.interceptors.response.use(response => {
  return response.data;
}, error => {
    return Promise.reject(error)
})

//文件上传
export const ServiceFile = axios.create({
  timeout: 7000, // 请求超时时间
  baseURL: ConfigBaseURL,
  method: "get",
});

// 添加请求拦截器
Service.interceptors.request.use(config => {
  console.log('cowww',config)
  config.headers.accessToken = store.state.supplier.accessToken;
  return config;
})
// 添加响应拦截器
Service.interceptors.response.use(response => {
  return response.data;
}, error => {
    return Promise.reject(error)
})



