import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import "./assets/icons";
import Vant from "vant";
import "vant/lib/index.css";
import "./assets/utils/phone";
import "@vant/touch-emulator";
import "./assets/utils/BMapGLLib";
Vue.config.ignoredElements = ["wx-open-launch-weapp"]; //微信小程序跳转配置
// import "lib-flexible";
import SlideVerify from "vue-monoplasty-slide-verify";
Vue.use(VueViewer)
Vue.use(SlideVerify);
Vue.use(Vant);
Vue.config.productionTip = false;
Vue.filter("dateFormat", function (time) {
  var hour = new Date(time).getTimezoneOffset() * 60000;
  var date = new Date(time + 28800000 + hour),
    year = date.getFullYear(),
    m = parseInt(date.getMonth()),
    mounth = m + 1 < 10 ? "0" + (m + 1) : m + 1,
    day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  return year + "-" + mounth + "-" + day;
});
Vue.filter("dateFormat1", function (time) {
  var hour = new Date(time).getTimezoneOffset() * 60000;
  var date = new Date(time + 28800000 + hour),
    year = date.getFullYear(),
    m = parseInt(date.getMonth()),
    mounth = m + 1 < 10 ? "0" + (m + 1) : m + 1,
    day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  return year + "/" + mounth + "/" + day;
});
Vue.filter("dateFormatPoint", function (originVal) {
  var hour = new Date(originVal).getTimezoneOffset() * 60000;
  var date = new Date(originVal + 28800000 + hour),
    year = date.getFullYear(),
    m = parseInt(date.getMonth()),
    mounth = m + 1 < 10 ? "0" + (m + 1) : m + 1,
    day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  return year + "." + mounth + "." + day;
});

Vue.filter("dateFormatPoint99", function (originVal) {
  var hour = new Date(originVal).getTimezoneOffset() * 60000;
  var date = new Date(originVal + 28800000 + hour),
    year = date.getFullYear(),
    m = parseInt(date.getMonth()),
    mounth = m + 1 < 10 ? "0" + (m + 1) : m + 1,
    day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  return year + "年" + mounth + "月" + day + "日";
});

Vue.filter("dateFormatHms", function (originVal) {
  const dt = new Date(originVal);

  const y = dt.getFullYear();
  const m = (dt.getMonth() + 1 + "").padStart(2, "0");
  const d = (dt.getDate() + "").padStart(2, "0");

  const hh = (dt.getHours() + "").padStart(2, "0");
  const mm = (dt.getMinutes() + "").padStart(2, "0");
  const ss = (dt.getSeconds() + "").padStart(2, "0");

  return `${y}-${m}-${d} ${hh}:${mm}:${ss}`;
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
