import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/OfficialAccountBind",
    name: "OfficialAccountBind",
    meta: {
      titile: "供应商绑定",
    },
    component: () => import("@/views/officialAccount/OfficialAccountBind.vue"),
  },
  {
    path: "/underLinePay",
    name: "underLinePay",
    component: () =>
      import(
        "@/views/underLinePay/underLinePay.vue"
      ),
  },
  {
    path: "/invoice",
    name: "invoice",
    component: () =>
      import(
        "@/views/invoice/invoice.vue"
      ),
  },
  {
    path: "/accountsPayableApproval",
    name: "accountsPayableApproval",
    component: () =>
      import(
        "@/views/accountsPayableApproval/accountsPayableApproval.vue"
      ),
  },
  {
    path: "/inquiryList",
    name: "inquiryList",
    component: () =>
      import(
        "@/views/inquiry/inquiryList.vue"
      ),
  },
  {
    path: "/revenueAndExpenditureApplication",
    name: "revenueAndExpenditureApplication",
    component: () =>
      import(
        "@/views/revenueAndExpenditureApplication/revenueAndExpenditureApplication.vue"
      ),
  },
  {
    path: "/orderDetail",
    name: "orderDetail",
    component: () => import("@/views/order/orderDetail.vue"),
  },
  {
    path: "/wxofficialAccount",
    name: "wxofficialAccount",
    component: () => import("@/views/wxofficialAccount/wxofficialAccount.vue"),
  },
  {
    path: "/RegisterMain",
    name: "RegisterMain",
    component: () => import("@/views/supplierRegister/RegisterMain.vue"),
  },
  {
    path: "/SupplierType",
    name: "SupplierType",
    component: () =>
      import("@/views/supplierRegister/children/SupplierType.vue"),
  },
  {
    path: "/ResourceSelect",
    name: "ResourceSelect",
    component: () => import("@/components/Register/ResourceSelect"),
  },
  {
    path: "/AreaSelect",
    name: "AreaSelect",
    component: () => import("@/components/Register/AreaSelect"),
  },
  {
    path: "/PlanShare",
    name: "PlanShare",
    component: () => import("@/views/plan/PlanShare.vue"),
  },
  {
    path: "/PlanBaiduMap",
    name: "PlanBaiduMap",
    component: () => import("@/views/plan/PlanBaiduMap.vue"),
  },
  {
    path: "/Collection",
    name: "Collection",
    component: () => import("@/views/plan/Collection.vue"),
    children: [
      {
        path: "/PlanCollection",
        name: "PlanCollection",
        component: () => import("@/views/plan/PlanCollection.vue"),
      },
      {
        path: "/Collection/PlanShare",
        name: "/Collection/PlanShare",
        component: () => import("@/views/plan/PlanShare.vue"),
      },
    ],
  },
  {
    path: "/planDetailMap",
    name: "planDetailMap",
    component: () => import("@/views/plan/children/PlanDetailMap.vue"),
  },
  {
    path: "/planDays",
    title: "地图概览",
    name: "planDays",
    component: () => import("@/views/plan/children/PlanDays.vue"),
  },
  {
    path: "/Collection/PlanDetail",
    name: "PlanDetail",
    meta: { keepAlive: true },
    component: () => import("@/views/plan/PlanDetail.vue"),
  },
  {
    path: "/PriceExplain",
    name: "PriceExplain",
    component: () => import("@/views/plan/PriceExplain.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
