<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">

    <div class="Test">
      <span>我来测试一下</span>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  },
  mounted() {
    this.$toast.success('成功文案');
  }
}
</script>
<style scoped lang="scss">
  .Test {
    height: 10rem;
    width: 10rem;
    background-color: red;
    font-size: 0.14rem;
  }
</style>
