import Vue from 'vue'
import SvgIcon from '@/components/SvgIcon/Svg' // svg组件
import store from '../../store/index'
// 注册为全局组件
Vue.component('svg-icon', SvgIcon)
const req = require.context('./svg', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)

let imagesNameArr = [];
for (let i = 0; i < req.keys().length; i++) {
	  let splitArr = req.keys()[i].substr(2, req.keys()[i].length).split(".")
    imagesNameArr.push(splitArr[0]);
}

if (imagesNameArr.length > 0) {
	store.state.svgArr = imagesNameArr
}
console.log("names",store.state.svgArr)
//currentImageUrl = require("…/assets/images" + imagesNameArr[i]);






