import Vue from 'vue'
import Vuex from 'vuex'
import supplier from './modules/supplier'
import createPersistedState from "vuex-persistedstate";
import collection from "@/store/modules/collection";

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    svgArr: []
  },
  modules: {
    //供应商入驻模块
    supplier,
    collection
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: [
          'supplier.accessToken',
          'supplier.supplierKey',
          'supplier.supplierInfoData',
          'collection.collectionId',
          'collection.ciphertext',
          'collection.planId',
          'collection.budgetMainId',
          'collection.type',
          'collection.priceDisplay',
          'collection.dayData',
          'collection.programmeId',
          'collection.openId',
          'collection.checkDay',
          'collection.planSetDetailMap',
          'collection.inquiryId',
          'collection.collectionLinkData',
          'collection.showGroup',
          'collection.payId',
          'collection.schemeUrl',
          'collection.isPay'
      ]
    }),
  ],
})
