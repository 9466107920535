( function (doc,win) {
  var docEl = doc.documentElement ,
			resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
			recalc = function () {
				//获取设备高度 并且设置 body 的高度为此数值
				var clientHeight = docEl.clientHeight
				console.log('sdkfisdjkfdf',clientHeight)
				document.body.style.height = clientHeight + 'px'
				//js获取当前的设备大小： document.documesntElement.clientWidth;
				var clientWidth = docEl.clientWidth; //获取设备的宽度
				if(!clientWidth) return;
				//设计稿宽度
				const deviceWidth  = 750
				if (clientWidth >= deviceWidth) { //宽度 > 750 平板 或者 桌面  rem = px / deviceWidth / 100
					docEl.style.fontSize = '100px';//根元素
				} else { //移动端的适配
					console.log('适配字体大小->',deviceWidth)
					//假设现在的基数是 40 如果我想设定一个 宽度为 40px 则 40 / 基数 = 1rem
					docEl.style.fontSize = 100 * (clientWidth / deviceWidth) + 'px';
				}
			};
	if (!doc.addEventListener) return;
	win.addEventListener(resizeEvt,recalc,false);
	doc.addEventListener('DOMContentLoaded',recalc,false); 
})( document,window) 