import Api from "@/components/Api";
import router from "@/router";
export default {
    namespaced: true, //开启命名空间
    state: {
        schemeUrl: '',
        showGroup: true,
        payId:'',//付款方Id
        collectionLinkData: '',
        collectionId: '',
        isPay:false,
        checkDay: '',
        openId: '',
        inquiryId: '',
        ciphertext: '',
        planId: '',
        budgetMainId: '',
        type: -1,
        programmeId:-1,//价格说明按钮是否显示 默认不显示
        priceDisplay: {
            // 方案下是否显示价格（方案详情内） 0 单人 1 总价
            programme: {
                checkProgrammeItems: [],
            },
            // 团组详情内是否显示价格 0 单人 1 总价 2 单价
            offer: {
                checkOfferItems: [],
            }
        },
        dayData: ''
    },
    mutations: {
        SET_IS_PAY(state,isPay) {
            state.isPay = isPay
        },
        SET_SCHEME_URL(state,schemeUrl) {
            state.schemeUrl = schemeUrl;
        },
        SET_SHOW_GROUP(state,showGroup) {
            state.showGroup = showGroup;
        },
        SET_LINK_DATA(state,linkData) {
            state.collectionLinkData = linkData
        },
        SET_CHECK_DAY(state,day) {
            state.checkDay = day
        },
        SET_OPENID(state, ID) {
            state.openId = ID
        },
        SET_INQUIRYID(state, ID) {
            state.inquiryId = ID
        },
        SET_PAYID(state, ID) {
            state.payId = ID
        },
        SET_COLLECTIONID(state, ID) {
            state.collectionId = ID
        },
        SET_CIPHERTEXT(state, ciphertext) {
            state.ciphertext = ciphertext
        },
        SET_PLANID(state, planId) {
            state.planId = planId
        },
        SET_MAINID(state, budgetMainId) {
            state.budgetMainId = budgetMainId
        },
        SET_PRICEDISPLA(state,priceDisplay) {
            state.priceDisplay = priceDisplay
        },
        SET_TYPE(state,type) {
            state.type = type
        },
        SET_DAY_DATA(state,dayData) {
            if (typeof dayData.budgetList !== 'undefined') {
                state.dayData = dayData
            }
        },
        //价格说明按钮是否显示 //id > 0 显示
        SET_PROGRAMMEID(state,id) {
            state.programmeId = id
        },
    },
    actions: {
        JUMP_TO_DEFAULT_LINK(context,value) {
            // 默认跳转到团组详情
            if (context.state.type === 1) {
                router.push({
                    name: 'PlanDetail',
                    query: {budgetMainId: context.state.budgetMainId, planId: context.state.planId}
                })
            }
            // 默认跳转到收款详情
            if (context.state.type === 2) {
                // 默认跳转
                router.push({
                    name: 'PlanCollection',
                    query: {collectionId: context.state.collectionId, ciphertext: context.state.ciphertext}
                })
            }
        }
    },
    getters: {}
}
