// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fontBold.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fontMedium.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */\n@font-face {\n    font-family: \"思源宋体 Medium\";\n    font-weight: 500;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    font-display: swap;\n}\n@font-face {\n    font-family: \"思源宋体Blod\";\n    font-weight: 500;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    font-display: swap;\n}\n", ""]);
// Exports
module.exports = exports;
